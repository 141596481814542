<script>
import appConfig from "@/app.config";

export default {
  page: {
    title: "500",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      footerYear: new Date().getFullYear()
    };
  },
  methods: {
    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }
  }
}
</script>
<template>
  <div class="authentication-bg d-flex align-items-center pb-0 vh-100">
    <div class="content-center w-100">
      <div class="container">
        <div class="card mo-mt-2 shadow">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-lg-4 ml-auto">
                <div class="ex-page-content">
                  <h1 class="text-danger display-1 mt-4">500</h1>
                  <h4 class="mb-4">Wystąpił nieoczekiwany błąd!</h4>
                  <p class="mb-5">Przepraszamy, wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie lub skontaktuj się z pomocą techniczną jeżeli problem nadal występuje.</p>
                  <router-link tag="a" class="btn btn-dark mb-5" to="/">
                    <i class="mdi mdi-home"></i> Wróć na stronę główną
                  </router-link>
                </div>
              </div>
              <div class="col-lg-5 mx-auto">
                <img src="@/assets/images/si-pay-error.jpg" alt class="img-fluid mx-auto d-block" />
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
      <div class="container-fluid text-center">
        <div class="row">
          <div class="col-12">
            © {{ footerYear }} <img src="@/assets/images/si-pay-logo.png" alt height="15" class="mt-n1" /> SI-PAY <span class="text-muted small">v1.2.0</span>
            <br/>
            <!-- <a href="#" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">Pomoc</a> -->
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
